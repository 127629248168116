<template>
  <div class="survey">
    <h2>Olá!</h2>
    <p>Como você avalia a entrega de</p>
    <p v-html="deliverableName"/>
    <div class="align-center check-response dont-response" v-show="!isMandatory">
      <input type="checkbox" @change="dontResponseSurvey()" />
      <label class="send-as-anonymous-label">Não quero responder</label>
    </div>
    <survey-boxes
      class="survey__boxes"
      :value="grade"
      @input="updateGrade"
      :disabled="disabled"
    />
    <div>
      <p>
        Gostaria de complementar com um comentário, elogio ou sugestão de
        melhoria?
      </p>
      <label class="align-left comment-label">Seu comentário</label>
    </div>
    <textarea 
      v-model="observation"
      class="input pa-4"
      placeholder="Deixe seu comentário..."
      rows="4"
      :class="{'disabled': disabled}"
    />
    <div class="align-left mt-3">
      <input v-model="sendAsAnonymous" type="checkbox"/>
      <label class="send-as-anonymous-label">Enviar como anônimo</label>
    </div>
    <div class="mt-3" v-show="!sendAsAnonymous">
      <label class="align-left comment-label">Nome</label>
      <input v-model="clientName" class="input pa-3"/>
      <label class="mt-3 align-left comment-label">E-mail</label>
      <input v-model="clientEmail" class="input pa-3"/>
    </div>
    <div class="mt-3 btn-container">
      <button 
        :class="{'btn': true, 'btn--disabled': grade === null && disabled === false}"
        :disabled="grade === null && disabled === false"
        @click="send"
      >
        Enviar
      </button>
    </div>
  </div>
</template>
<script>
import SurveyBoxes from '../components/SurveyBoxesToSelect.vue';
import Survey from '../domain/entity/Survey.entity';
import SurveyService from '../domain/service/SurveyService';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default {
  components: {
    SurveyBoxes,
  },
  data: () => ({
    deliverableIds: [],
    deliverableName: '',
    grade: null,
    observation: '',
    sendAsAnonymous: true,
    clientName: '',
    clientEmail: '',
    disabled: false,
    isMandatory: true
  }),
  methods: {
    updateGrade(grade) {
      this.grade = grade;
    },
    dontResponseSurvey() {
      this.disabled = !this.disabled;
      this.observation = null;
      this.grade = null;
    },
    getSurvey() {
      if (this.sendAsAnonymous) {
        return new Survey({
          deliverableIds: this.deliverableIds,
          grade: this.grade,
          observation: this.observation,
        });
      }
      return new Survey({
        deliverableIds: this.deliverableIds,
        grade: this.grade,
        observation: this.observation,
        clientName: this.clientName,
        clientEmail: this.clientEmail,
      });
    },
    send() {
      const survey = this.getSurvey();

      if(survey.grade !== null) {
        SurveyService.answerSurvey(survey)
          .then().catch(() => {
            const errorMessage = 'Tivemos um erro ao enviar sua avaliação, tente novamente mais tarde!';
            createToast(errorMessage, { type: 'danger' });
          }
        );
      }

      createToast("Avaliação enviada com sucesso!", { type: "success" });
      this.$router.push({ name: 'thank-you' });
    },
  },
  created() {
  const idSurvey = this.$route.params.id;

  SurveyService.getLinkSurvey(idSurvey)
    .then(result => {
      const data = result.data;

      this.deliverableIds = data.deliverables.map(x => x.id);
      
      if (data.demandName && data.demandName.trim() !== "") {
        this.deliverableName = data.demandName;
      } else {
        this.deliverableName = data.deliverables.map(x => x.name).join('<br>');
      }
      
      this.isMandatory = data.isMandatory;
    })
    .catch(() => {
      const errorMessage = 'Tivemos um erro ao obter a sua pesquisa de satisfação, tente novamente mais tarde!';
      createToast(errorMessage, { type: 'danger' });
    });
},
}
</script>
<style scoped>
.survey {
  margin-top: 40px;
  width: 395px;
  margin: 0 auto;
}
.survey__boxes {
  margin-bottom: 20px;
}
.btn-container {
  margin-top: 20px;
}
.btn {
  padding: 8px;
  border: none;
  background-color: #41172b;
  color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 40px;
  width: 100px;
  cursor: pointer;
}
.btn.btn--disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
}
.input {
  background: #f3f3f3;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-top: 2px;
  
}
.align-left {
  display: flex;
  justify-content: flex-start;
}
.send-as-anonymous-label {
  font-size: 14px;
  margin-top: 2px;
}
.comment-label {
  font-size: 14px;
  font-weight: bold;
}
.mt-3 {
  margin-top: 12px;
}
.pa-4 {
  padding: 16px;
}
.pa-3 {
  padding: 12px;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
  padding-bottom: 20px;
}
.dont-response {
  padding-bottom: 20px;
}
</style>
